document.addEventListener('DOMContentLoaded', function () {
	initFancyBox()
	initFormValidation()
	initMenu()
	initSmoothScrolling()
	initSwiper()
	initTabs()
    initTabs2()
    initTabs3()
	inputMaskCustom()
	scrollActive()
	// lazyLoadImg()

})
