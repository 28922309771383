function initTabs3() {
    try {
        const blockTab = document.querySelectorAll('.tabs-3__block')
        const itemMenuTab = document.querySelectorAll('.tabs-3__item')

        let indexElementTab
        let identificationTab

        blockTab.forEach(function (itemBlock) {
            itemMenuTab.forEach(function (itemItem) {
                if (itemBlock.classList.contains('tabs-3__block--not-active')) {
                    itemBlock.style.display = 'none'
                }
                itemBlock.classList.remove('tabs-3__block--active')
            })
        })

        blockTab.forEach(function (itemBlock) {
            itemMenuTab.forEach(function (itemItem) {
                itemItem.addEventListener('click', function () {
                    identificationTab = itemItem.getAttribute('data-identification-tab')
                    indexElementTab = itemItem.getAttribute('data-index-tab')
                    if (indexElementTab === itemBlock.getAttribute('data-index-tab')) {
                        blockTab.forEach(function (itemBlock2) {
                            itemMenuTab.forEach(function (itemItem2) {
                                if (identificationTab === itemBlock2.getAttribute('data-identification-tab')) {
                                    itemBlock2.classList.remove('tabs-3__block--active')
                                    itemBlock2.classList.add('tabs-3__block--not-active')
                                }
                                if (identificationTab === itemItem2.getAttribute('data-identification-tab')) {
                                    itemItem2.classList.remove('tabs-3__item--active')
                                }
                            })
                        })
                        itemItem.classList.add('tabs-3__item--active')
                        itemBlock.classList.remove('tabs-3__block--not-active')
                        itemBlock.classList.add('tabs-3__block--active')

                        blockTab.forEach(function (itemBlock2) {
                            identificationTab = itemItem.getAttribute('data-identification-tab')
                            itemMenuTab.forEach(function (itemItem2) {
                                if (identificationTab === itemBlock2.getAttribute('data-identification-tab')) {
                                    if (itemBlock2.classList.contains('tabs-3__block--not-active')) {
                                        setTimeout(function () {
                                            itemBlock2.style.display = 'none'
                                        }, 400)
                                    } else {
                                        setTimeout(function () {
                                            itemBlock2.style.display = 'block'
                                        }, 400)
                                    }
                                }
                            })
                        })
                    }
                })
            })
        })

    } catch {
        console.log('Ошибка inittabs-3')
    }
}
