function initSwiper() {
	let sliderPartners = new Swiper('.partners__swiper-container', {
		slidesPerView: 2,
		spaceBetween: 20,
		grabCursor: true,
		// autoplay: {
		// 	delay: 3000,
		// 	disableOnInteraction: true,
		// },
		pagination: {
			el: '.swiper-pagination',
		},
		loop: true,
		pagination: {
			el: ".swiper-pagination",
		},
	})
}
